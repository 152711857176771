import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { AppContext } from "App";
import { helpers } from "helpers/helpers";
import { useFormik } from "formik";
import { addNewAutocitireSchema } from "schemas/yupSchema";
import { toast } from "react-toastify";
import { apiLocuriDeConsum } from "api/locuriDeConsum.service";
import { apiAutocitire } from "api/autocitire.service";
import objectSchema from "schemas/objectSchema";
import TextInput from "components/TextInput";
import SelectInput from "components/SelectInput";
import SubmitButton from "components/SubmitButton";
import { ReactComponent as ICON_CLOSE } from "assets/images/common/x_button.svg";
import GAZ from "assets/images/common/gaz.png";
import CURENT from "assets/images/common/curent.png";
import { format } from "date-fns";
import { requestJson, RequestMethod } from "helpers/httpHelper";
import ComponentLoading from "components/Loaders/ComponentLoading";

export default function ModalAdaugaAutocitire({
  setShowAdaugaAutocitire,
  setFetchAgainAutocitiri,
  selectedFilter,
}) {
  const { setIsScreenLoading } = useContext(AppContext);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [optionsMeteringPoints, setOptionsMeteringPoints] = useState([]);
  const [filteredOptionsMeteringPoints, setFilteredOptionsMeteringPoints] =
    useState([]);
  const [optionsMeterSeries, setOptionsMeterSeries] = useState([]);
  const [filteredOptionsMeterSeries, setFilteredOptionsMeterSeries] = useState(
    [],
  );
  const [optionsUtilityType, setOptionsUtilityType] = useState([]);

  const [oldIndex, setOldIndex] = useState(0);
  const [diffIndex, setDiffIndex] = useState(0);
  const [isFetching, setIsFetching] = useState(false);

  const today = format(new Date(), "yyyy.MM.dd");
  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: isFormSubmitted,
    initialValues: {
      utility_type: "",
      metering_point_id: "",
      date: today,
      total_consumption: "",
    },
    validationSchema: addNewAutocitireSchema,
    onSubmit: async (values) => {
      if (diffIndex < 0) {
        toast.error(
          "Indexul introdus trebuie sa fie mai mare decat indexul vechi!",
        );
        return;
      }

      helpers.scrollToTop();
      setIsScreenLoading(true);
      await addNewAutocitire(values);
      setIsScreenLoading(false);
    },
  });

  const addNewAutocitire = async (values) => {
    const response = await apiAutocitire.addNewAutocitire(values);
    if (response.isSuccess) {
      toast.success(response.response.message);
      setFetchAgainAutocitiri((prevState) => prevState + 1);
      setShowAdaugaAutocitire(false);
    } else toast.error("A aparut o eroare la salvare autocitire!");
  };

  const getMeteringPointsOptions = async () => {
    const response = await requestJson(
      "selfreadings/metering-points",
      RequestMethod.GET,
      { useAccessToken: true },
    );

    if (response.isSuccess) {
      const mapMeteringPoint = (item) => ({
        value: item.metering_point_id,
        label: item.address_summary, // Changed to string instead of React component
        type: item.utility_type === "Gaze" ? "gaz" : "energy",
        metering_point_number: item.metering_point_number,
        utilityIcon: item.utility_type === "Gaze" ? GAZ : CURENT, // Store icon source
      });

      const mappedPoints = response.response.data.map(mapMeteringPoint);
      setOptionsMeteringPoints(mappedPoints);

      // Initial filtering based on selected filter
      if (selectedFilter && selectedFilter !== "TOATE") {
        const defaultUtilityType = filterUtilityTypeMap[selectedFilter];
        const filteredPoints = mappedPoints.filter(
          (loc) => loc.type === defaultUtilityType,
        );
        setFilteredOptionsMeteringPoints(filteredPoints);
      } else {
        setFilteredOptionsMeteringPoints(mappedPoints);
      }

      return response.response.data;
    }
    return null;
  };

  const getMeterSeriesOptions = async () => {
    const response = await apiLocuriDeConsum.getMeterSeries();
    if (response.isSuccess) {
      setOptionsMeterSeries(
        response.response.data.map((item) => {
          return {
            value: item.meter_series,
            utility_type: item.utility_type,
            label: (
              <div>
                <img
                  src={item.utility_type === "gas" ? GAZ : CURENT}
                  height={"30px"}
                  alt="icon_gaz_curent"
                />
                {" " + item.meter_series}
              </div>
            ),
            metering_point_number: item.metering_point_number,
            old_index: item.old_index,
          };
        }),
      );

      setFilteredOptionsMeterSeries(
        response.response.data.map((item) => ({
          value: item.meter_series,
          label: (
            <div>
              <img
                src={item.utility_type === "gas" ? GAZ : CURENT}
                height={"30px"}
                alt="icon_gaz_curent"
              />
              {" " + item.meter_series}
            </div>
          ),
          metering_point_number: item.metering_point_number,
          old_index: item.old_index,
        })),
      );
    }
  };
  const filterUtilityTypeMap = {
    GAZ: objectSchema.optionsUtilityType[0].value,
    gaz: objectSchema.optionsUtilityType[0].value,
    CURENT: objectSchema.optionsUtilityType[1].value,
    energy: objectSchema.optionsUtilityType[1].value,
  };
  const initOptionsUtilityType = (meeterPoints) => {
    // objectSchema.optionsUtilityType
    const optionsUtilityTypeFound = {
      gaz: false,
      curent: false,
    };
    const optionsUtilityTypeInit = [];
    meeterPoints.forEach((item) => {
      if (
        item.utility_type === "Gaze" &&
        optionsUtilityTypeFound.gaz === false
      ) {
        optionsUtilityTypeFound.gaz = true;
      }
      if (
        item.utility_type === "Electricitate" &&
        optionsUtilityTypeFound.curent === false
      ) {
        optionsUtilityTypeFound.curent = true;
      }
    });

    if (optionsUtilityTypeFound.gaz === true) {
      optionsUtilityTypeInit.push(objectSchema.optionsUtilityType[0]);
    }
    if (optionsUtilityTypeFound.curent) {
      optionsUtilityTypeInit.push(objectSchema.optionsUtilityType[1]);
    }

    setOptionsUtilityType(optionsUtilityTypeInit);
  };
  useEffect(() => {
    const getInitialData = async () => {
      setIsFetching(true);
      const meeterPoints = await getMeteringPointsOptions();
      initOptionsUtilityType(meeterPoints);
      if (meeterPoints.length === 0) {
        setIsFetching(false);
        return;
      }
      await getMeterSeriesOptions();
      setIsFetching(false);
    };

    getInitialData();
  }, []);

  useEffect(() => {
    const initUtilityType = () => {
      const utilityTypeMeteringpointsMap = {
        GAZ: "gaz",
        CURENT: "energy",
      };
      if (selectedFilter === "TOATE") {
        setFilteredOptionsMeteringPoints(optionsMeteringPoints);
        return;
      }

      const defaultUtilityType = filterUtilityTypeMap?.[selectedFilter];
      formik.setFieldValue("utility_type", defaultUtilityType);

      if (optionsMeteringPoints.length === 0) {
        return;
      }
      const utilityTypeMeeteringPoints =
        utilityTypeMeteringpointsMap[selectedFilter];

      const filteredLocs = optionsMeteringPoints.filter((loc) => {
        return loc.type === utilityTypeMeeteringPoints;
      });

      setFilteredOptionsMeteringPoints(filteredLocs);
      if (filteredLocs.length === 1) {
        formik.setFieldValue("metering_point_id", filteredLocs[0].value);
      }
      if (optionsMeterSeries.length === 0) {
        return;
      }

      const filteredMeterSeries = optionsMeterSeries.filter((contor) => {
        return contor.utility_type === utilityTypeMeeteringPoints;
      });

      if (filteredLocs.length === 1 && filteredMeterSeries.length === 1) {
        formik.setFieldValue("meter_series", filteredMeterSeries[0].value);
      }

      setFilteredOptionsMeterSeries(filteredMeterSeries);
    };

    initUtilityType();
  }, [selectedFilter, optionsMeteringPoints.length, optionsMeterSeries.length]);

  useEffect(() => {
    if (formik.values.total_consumption !== "") {
      setDiffIndex(formik.values.total_consumption - oldIndex);
    }
  }, [formik.values.total_consumption, oldIndex]);

  return (
    <>
      {ReactDOM.createPortal(
        <div className="modifica-date-container">
          <div className="modifica-date-content">
            <div className="modifica-date-header">
              <h2>Adaugă Autocitire</h2>
              <ICON_CLOSE
                style={{ cursor: "pointer" }}
                onClick={() => setShowAdaugaAutocitire(false)}
              />
            </div>

            {isFetching ? (
              <ComponentLoading />
            ) : (
              <form onSubmit={formik.handleSubmit}>
                <div className="modifica-date-textInput-row">
                  <SelectInput
                    parrentClassName={"modifica-date-modal-select"}
                    reactSelectClassName={"modifica-date-modal-react-select"}
                    options={optionsUtilityType}
                    name={"utility_type"}
                    id={"utility_type"}
                    labelDescription={"Tip Furnizare"}
                    value={formik.values.utility_type}
                    onChange={(item) => {
                      const utilityTypeMap = {
                        energy: "energy",
                        gaz: "gaz",
                        gas: "gaz",
                      };
                      formik.setFieldValue("utility_type", item.value);
                      formik.setFieldValue("metering_point_id", "");
                      formik.setFieldValue("meter_series", "");

                      setFilteredOptionsMeteringPoints(
                        optionsMeteringPoints.filter((loc) => {
                          return loc.type === utilityTypeMap[item.value];
                        }),
                      );

                      setFilteredOptionsMeterSeries(
                        optionsMeterSeries.filter((contor) => {
                          return (
                            contor.utility_type === utilityTypeMap[item.value]
                          );
                        }),
                      );
                    }}
                    placeholder="Selectează tip furnizare..."
                    error={formik.errors.utility_type}
                    touched={formik.touched.utility_type}
                  />
                </div>
                <div className="modifica-date-textInput-row">
                  <SelectInput
                    parrentClassName={"modifica-date-modal-select"}
                    reactSelectClassName={"modifica-date-modal-react-select"}
                    options={filteredOptionsMeteringPoints.map((option) => ({
                      ...option,
                      label: (
                        <div>
                          <img
                            alt={`icon-${option.type}`}
                            src={option.utilityIcon}
                            height={"30px"}
                          />
                          {" " + option.label}
                        </div>
                      ),
                    }))}
                    name={"metering_point_id"}
                    id={"metering_point_id"}
                    labelDescription={"Loc de consum"}
                    value={formik.values.metering_point_id}
                    onChange={(item) => {
                      formik.setFieldValue("metering_point_id", item.value);
                      formik.setFieldValue("meter_series", "");
                      const filteredMeterSeries = optionsMeterSeries.filter(
                        (contor) => {
                          return (
                            contor.metering_point_number ===
                            item.metering_point_number
                          );
                        },
                      );

                      setFilteredOptionsMeterSeries(filteredMeterSeries);

                      if (filteredMeterSeries.length === 1) {
                        formik.setFieldValue(
                          "meter_series",
                          filteredMeterSeries[0].value,
                        );
                      }
                    }}
                    placeholder="Selectează locul de consum..."
                    error={formik.errors.metering_point_id}
                    touched={formik.touched.metering_point_id}
                  />
                </div>
                <div className="modifica-date-textInput-row">
                  <SelectInput
                    parrentClassName={"modifica-date-modal-select"}
                    reactSelectClassName={"modifica-date-modal-react-select"}
                    options={filteredOptionsMeterSeries}
                    name={"meter_series"}
                    id={"meter_series"}
                    labelDescription={"Contor"}
                    value={formik.values.meter_series}
                    onChange={(item) => {
                      formik.setFieldValue("meter_series", item.value);
                      setOldIndex(item.old_index);
                    }}
                    placeholder="Selectează contor..."
                    error={formik.errors.meter_series}
                    touched={formik.touched.meter_series}
                  />
                </div>
                <div className="modifica-date-textInput-row">
                  <h2>Data Autocitirii</h2>
                  <TextInput
                    id={"date"}
                    name={"date"}
                    type={"text"}
                    placeholder={"Selectează data..."}
                    classNames={"modifica-date-modal-textInput"}
                    value={today}
                    error={formik.errors.date}
                    touched={formik.touched.date}
                    setFieldValue={formik.setFieldValue}
                    allowNumbersAndDots={true}
                    disabled={true}
                  />
                </div>
                <div className="modifica-date-textInput-row">
                  <h2>Index</h2>
                  <TextInput
                    id={"total_consumption"}
                    name={"total_consumption"}
                    type={"text"}
                    placeholder={"Index"}
                    classNames={"modifica-date-modal-textInput"}
                    value={formik.values.total_consumption}
                    error={formik.errors.total_consumption}
                    touched={formik.touched.total_consumption}
                    setFieldValue={formik.setFieldValue}
                    allowNumbersAndDots={true}
                  />
                </div>
                <div className="modifica-date-textInput-row">
                  <h2>Index Vechi</h2>
                  <input
                    readOnly={true}
                    placeholder={"Index Vechi"}
                    className={
                      "custom-textInput modifica-date-modal-textInput readonly"
                    }
                    value={oldIndex}
                    disabled={true}
                  />
                </div>
                <div className="modifica-date-textInput-row">
                  <h2>Diferenta(Consum)</h2>
                  <input
                    readOnly={true}
                    placeholder={"Selecteaza contor"}
                    className={
                      "custom-textInput modifica-date-modal-textInput readonly "
                    }
                    value={diffIndex}
                    disabled={true}
                  />
                </div>
                <div className="modifica-date-modal-end-buttons">
                  <SubmitButton
                    onClick={() => setShowAdaugaAutocitire(false)}
                    classNames={"buton-bg-alb-green"}
                    text={"Inchide"}
                  />
                  <SubmitButton
                    onClick={() => setIsFormSubmitted(true)}
                    typeOfButton={"submit"}
                    text={"Adaugă"}
                  />
                </div>
              </form>
            )}
          </div>
        </div>,
        document.getElementById("modifica-date-modal"),
      )}
    </>
  );
}
