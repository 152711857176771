import { requestJson, RequestMethod } from "helpers/httpHelper";

const getRapoarte = async () => {
  return await requestJson("reports", RequestMethod.GET, {
    useAccessToken: true,
  });
};

export const apiRapoarte = {
  getRapoarte,
};
