import React, { useState, useEffect } from "react";
import { apiContulMeu } from "api/contulMeu.service";
import ComponentLoading from "components/Loaders/ComponentLoading";
import ContracteleMeleCard from "components/ContulMeu/ContracteleMeleCard";
import SubmitButton from "components/SubmitButton";

export default function ContracteleMele() {
  const [contracte, setContracte] = useState([]);
  const [isDataFetching, setIsDataFetching] = useState(false);

  useEffect(() => {
    const getMyContracts = async () => {
      setIsDataFetching(true);
      const response = await apiContulMeu.myContracts();
      if (response.isSuccess) {
        setContracte(response.response.data);
        setIsDataFetching(false);
      }
    };
    getMyContracts();
  }, []);

  return (
    <div className="contractele-mele-div">
      <div className="contractele-mele-header">
        <h2>Contracte</h2>
      </div>
      {isDataFetching ? (
        <ComponentLoading />
      ) : (
        <div className="contractele-mele-container">
          {contracte.map((contract, index) => {
            const isLastItem = index === contracte.length - 1;
            return (
              <ContracteleMeleCard
                key={contract.contract_number + index}
                item={contract}
                isLastItem={isLastItem}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}
