import React from "react";
import GRAPH_LOW from "assets/images/common/graph_low.svg";
import GRAPH_HIGH from "assets/images/common/graph_high.svg";

export default function RapoarteInfoText({
  yearlyAvgDailyCost = 0,
  yearlyAvgMWH = 0,
  yearlyAvgDailyQty = 0,
}) {
  return (
    <div className="rapoarte-grafic-simplificat-info-container">
      <div className="rapoarte-grafic-simplificat-info">
        <h2>Consum mediu lunar in 2024</h2>
        <div className="d-flex">
          <p>{yearlyAvgMWH.toFixed(2)} MWh</p>
          <img src={GRAPH_LOW} alt="arrow" />
        </div>
      </div>

      <div className="rapoarte-grafic-simplificat-info">
        <h2>Consum mediu zilnic in 2024</h2>
        <div className="d-flex">
          <p>{yearlyAvgDailyQty.toFixed(2)} MWh</p>
          <img src={GRAPH_HIGH} alt="arrow" />
        </div>
      </div>

      <div className="rapoarte-grafic-simplificat-info">
        <h2>Cost mediu zilnic in 2024</h2>
        <div className="d-flex">
          <p>{yearlyAvgDailyCost.toFixed(2)} RON</p>
          <img src={GRAPH_LOW} alt="arrow" />
        </div>
      </div>
    </div>
  );
}
