import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "App";
import { useFormik } from "formik";
import { loginSchema } from "schemas/yupSchema";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthService } from "api/auth.service";
import { apiContulMeu } from "api/contulMeu.service";
import { storageHelpers } from "helpers/storageHelpers";
import { toast } from "react-toastify";
import TextInput from "components/TextInput";
import SubmitButton from "components/SubmitButton";
import DOWN_BACKGROUND from "assets/images/login/login-down-background.png";
import BACKGROUND from "assets/images/login/background.png";
import LOGO from "assets/images/login/logo.png";
import BACKGROUND_MOBILE_TOP from "assets/images/login/background_mobile_top.png";
import BACKGROUND_MOBILE_BOTTOM from "assets/images/login/background_mobile_bottom.png";
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
export default function Login() {
  const { setIsScreenLoading } = useContext(AppContext);
  const navigation = useNavigate();

  const location = useLocation();
  let query = useQuery();

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: isFormSubmitted,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      handleLogin(values);
    },
  });

  const handleLogin = async (values) => {
    setIsScreenLoading(true);
    const response = await AuthService.login(values.email, values.password);
    if (response.isSuccess) {
      storageHelpers.setUserToken(response.response.token);
      const from = query.get("redirect");
      navigation(from || "/dashboard");

      const accountDetails = await apiContulMeu.myAccountDetails();
      if (accountDetails.isSuccess) {
        storageHelpers.setAssociatedUsersForMainAccount(
          accountDetails?.response?.associated_accounts,
        );
      }
    } else {
      toast.error("Autentificare esuata!");
    }
    setIsScreenLoading(false);
  };

  useEffect(() => {
    if (location.pathname === "/" && storageHelpers.isUserLoggedIn()) {
      const { from } = location.state || { from: { pathname: "/dashboard" } };
      navigation(from);
      toast.success("Esti logat!");
    }
  }, []);

  return (
    <div className="login-page">
      <div className="login-main-container">
        <img src={LOGO} className="login-logo" alt="logo" />
        <h1>Autentificare</h1>
        <form className="login-form" onSubmit={formik.handleSubmit}>
          <div className="login-input-row">
            <TextInput
              isLoginInput={true}
              id={"email"}
              name={"email"}
              type={"text"}
              placeholder={"Email"}
              classNames={"login-textInput"}
              value={formik.values.email}
              error={formik.errors.email}
              touched={formik.touched.email}
              setFieldValue={formik.setFieldValue}
            />
          </div>
          <div className="login-input-row">
            <TextInput
              id={"password"}
              name={"password"}
              type={"password"}
              placeholder={"Parola"}
              classNames={"login-textInput"}
              value={formik.values.password}
              error={formik.errors.password}
              touched={formik.touched.password}
              setFieldValue={formik.setFieldValue}
            />
          </div>
          <a onClick={() => navigation("/recoverpassword")}>Am uitat parola</a>
          <SubmitButton
            onClick={() => setIsFormSubmitted(true)}
            typeOfButton={"submit"}
            text={"Autentifica-te"}
            classNames={"login-button"}
          />
        </form>
      </div>
      <img
        src={DOWN_BACKGROUND}
        className="login-down-background"
        alt="down-background"
      />
      <img src={BACKGROUND} className="login-background" alt="background" />
      <img
        src={BACKGROUND_MOBILE_TOP}
        className="login-background-mobile-top"
        alt="background-mobile-top"
      />
      <img
        src={BACKGROUND_MOBILE_BOTTOM}
        className="login-background-mobile-bottom"
        alt="background-mobile-bottom"
      />
    </div>
  );
}
