import React from "react";
import Layout from "pages/Layout";
import SoldCurent from "components/Dashboard/SoldCurent";
import LocuriDeConsum from "components/Dashboard/LocuriDeConsum";
import DashboardGrafic from "components/Dashboard/DashboardGrafic";
import DataTableFacturileMele from "components/FacturiSiPlati/DataTableFacturileMele";

export default function Dashboard({
  isDataFetching,
  facturileMeleTableData,
  soldCurent,
}) {
  return (
    <Layout>
      <div className="dashboard-content-div">
        <DashboardGrafic
          isDataFetching={isDataFetching}
          facturileMeleTableData={facturileMeleTableData}
        />
        <DataTableFacturileMele
          isDataFetching={isDataFetching}
          facturileMeleTableData={facturileMeleTableData}
          dashboard={true}
        />
        <SoldCurent soldCurent={soldCurent} isDataFetching={isDataFetching} />
        <LocuriDeConsum />
      </div>
    </Layout>
  );
}
