import React, { useState, useEffect, useRef } from "react";
import { apiContulMeu } from "api/contulMeu.service";
import ModalModificaDate from "components/ContulMeu/Modal/ModalModificaDate";
import ModalSchimbaParola from "components/ContulMeu/Modal/ModalSchimbaParola";
import SubmitButton from "components/SubmitButton";
import ComponentLoading from "components/Loaders/ComponentLoading";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

export default function ContulMeuDetails() {
  const [userDetails, setUserDetails] = useState({
    data: [],
    fetchAgain: 0,
  });
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [showModificaDate, setShowModificaDate] = useState(false);
  const [showModficaParola, setShowModficaParola] = useState(false);
  const navigation = useNavigate();
  useEffect(() => {
    const getUserDetails = async () => {
      setIsDataFetching(true);
      const response = await apiContulMeu.getAllAccountDetails();
      if (response.isSuccess) {
        setUserDetails((prevState) => ({
          ...prevState,
          data: response.response.data,
        }));
        setIsDataFetching(false);
      }
    };
    getUserDetails();
  }, [userDetails.fetchAgain]);

  if (showModificaDate || showModficaParola)
    document.body.style.overflow = "hidden";
  else document.body.style.overflow = "auto";

  const modifyData = () => {
    navigation("/suport");
    toast.info("Contacti suportul pentru a modifica datele.");
  };

  const deleteAccount = () => {
    navigation("/suport");
    toast.info("Contacti suportul pentru a sterge contul.");
  };
  return (
    <div className="my-account-div">
      <h2>Contul meu</h2>
      {isDataFetching ? (
        <ComponentLoading parrentClassName={"my-account-loading-div"} />
      ) : (
        <>
          <div className="my-account-div-content">
            <div className="my-account-details">
              <p>Adresa</p>
              <p>{userDetails.data.address}</p>
            </div>

            <div className="my-account-details">
              <p>Email</p>
              <p>{userDetails.data.email}</p>
            </div>

            <div className="my-account-details">
              <p>Telefon</p>
              <p>{userDetails.data.phone_number}</p>
            </div>
          </div>
          <div className="my-account-line" />
          <div className="my-account-buttons">
            <SubmitButton
              onClick={modifyData}
              classNames={"buton-bg-alb-green"}
              text={"Modifica date"}
            />
            <SubmitButton
              onClick={() => setShowModficaParola(true)}
              classNames={"buton-bg-alb-green"}
              text={"Schimba parola"}
            />

            <SubmitButton
              onClick={deleteAccount}
              classNames={"buton-bg-alb-red ml-4"}
              text={"Sterge cont"}
            />
          </div>

          {showModificaDate && (
            <ModalModificaDate
              userDetails={userDetails}
              setUserDetails={setUserDetails}
              setShowModificaDate={setShowModificaDate}
            />
          )}

          {showModficaParola && (
            <ModalSchimbaParola setShowModficaParola={setShowModficaParola} />
          )}
        </>
      )}
    </div>
  );
}
