import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import RapoarteInfoText from "components/Rapoarte/RapoarteInfoText";
import reportsHelper from "helpers/reportsHelper";
import { isObject } from "lodash";
// Default empty chart options for when data is not available
const emptyChartOptions = {
  baseOption: {
    title: {
      text: "Nu există date disponibile",
      left: "center",
      top: "center",
    },
    xAxis: { type: "category", data: [] },
    yAxis: { type: "value" },
    series: [],
  },
};

export default function TabRaportSimplificat({
  data = {
    gas: [],
    energy: [],
  },
}) {
  console.log("🚀 ~ TabRaportSimplificat ~ data:", data);
  const [raportEnergieElectrica, setRaportEnergieElectrica] =
    useState(emptyChartOptions);
  const [raportGaze, setRaportGaze] = useState(emptyChartOptions);

  useEffect(() => {
    if (!data) {
      return;
    }
    console.log("typeof ", typeof data.energy);
    try {
      const graphDataEnergy = isObject(data.energy)
        ? reportsHelper.createGraphOptions(
            data.energy,
            "monthlySumMWH",
            "energy",
          )
        : emptyChartOptions;

      setRaportEnergieElectrica(graphDataEnergy);

      const graphDataGaze = isObject(data.gas)
        ? reportsHelper.createGraphOptions(data.gas, "monthlySumMWH", "gas")
        : emptyChartOptions;

      setRaportGaze(graphDataGaze);
    } catch (error) {
      console.error("Error processing chart data:", error);
      setRaportEnergieElectrica(emptyChartOptions);
      setRaportGaze(emptyChartOptions);
    }
  }, [data]);

  if (!data) {
    return <div>Rapoartele sunt indisponibile</div>;
  }

  const hasEnergyData = isObject(data.energy) && data.gas !== null;
  const hasGasData = isObject(data.gas) && data.gas !== null;

  return (
    <>
      <div className="rapoarte-grafic-simplificat">
        <div className="rapoarte-grafic-simplificat-chart-and-header">
          <div className="rapoarte-grafic-simplificat-title-row">
            <h2>Energie electrica</h2>
            <p>Consum energie electrica 2025 comparat cu 2024</p>
          </div>
          {raportEnergieElectrica && (
            <ReactECharts
              option={raportEnergieElectrica}
              className="rapoarte-grafic-simplificat-chart"
              notMerge={true}
              lazyUpdate={true}
            />
          )}
        </div>
        {hasEnergyData && (
          <RapoarteInfoText
            yearlyAvgDailyCost={data.energy?.["2024"]?.yearlyAvgDailyCost}
            yearlyAvgMWH={data.energy?.["2024"]?.yearlyAvgMWH}
            yearlyAvgDailyQty={data.energy?.["2024"]?.yearlyAvgDailyQty}
          />
        )}
      </div>

      <div className="rapoarte-grafic-simplificat">
        <div className="rapoarte-grafic-simplificat-chart-and-header">
          <div className="rapoarte-grafic-simplificat-title-row">
            <h2>Gaze</h2>
            <p>Consum energie electrica 2025 comparat cu 2024</p>
          </div>
          {raportGaze && (
            <ReactECharts
              option={raportGaze}
              className="rapoarte-grafic-simplificat-chart"
              notMerge={true}
              lazyUpdate={true}
            />
          )}
        </div>
        {hasGasData && (
          <RapoarteInfoText
            yearlyAvgDailyCost={data.gas?.["2024"]?.yearlyAvgDailyCost}
            yearlyAvgMWH={data.gas?.["2024"]?.yearlyAvgMWH}
            yearlyAvgDailyQty={data.gas?.["2024"]?.yearlyAvgDailyQty}
          />
        )}
      </div>
    </>
  );
}
