import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import reportsHelper from "helpers/reportsHelper";
import { isObject } from "lodash";

// Default empty chart options for when data is not available
const emptyChartOptions = {
  baseOption: {
    title: {
      text: "Nu există date disponibile",
      left: "center",
      top: "center",
    },
    xAxis: { type: "category", data: [] },
    yAxis: { type: "value" },
    series: [],
  },
};

export default function TabRaportDetaliat({
  data = {
    gas: [],
    energy: [],
  },
}) {
  // State for charts
  const [prezentareChart, setPrezentareChart] = useState(emptyChartOptions);
  const [energieZilnicChart, setEnergieZilnicChart] =
    useState(emptyChartOptions);
  const [energieCostChart, setEnergieCostChart] = useState(emptyChartOptions);
  const [gazeZilnicChart, setGazeZilnicChart] = useState(emptyChartOptions);
  const [gazeCostChart, setGazeCostChart] = useState(emptyChartOptions);

  useEffect(() => {
    if (!data) {
      return;
    }

    try {
      // Process data for overview/presentation chart
      const prezentareData =
        isObject(data.energy) && isObject(data.gas)
          ? reportsHelper.createGraphOptionsForInvoiceAmounts({
              ...data.energy,
              // ...data.gas,
            })
          : emptyChartOptions;
      setPrezentareChart(prezentareData);

      // Process electricity data
      if (isObject(data.energy)) {
        const dailyConsumptionChart = reportsHelper.createGraphOptions(
          data.energy,
          "monthlySumAvgDaily",
          "energy",
        );
        setEnergieZilnicChart(dailyConsumptionChart);

        const costChart = reportsHelper.createGraphOptions(
          data.energy,
          "monthlySumAvgDailyCost",
          "energy",
        );
        setEnergieCostChart(costChart);
      } else {
        setEnergieZilnicChart(emptyChartOptions);
        setEnergieCostChart(emptyChartOptions);
      }

      // Process gas data
      if (isObject(data.gas)) {
        const dailyConsumptionChart = reportsHelper.createGraphOptions(
          data.gas,
          "monthlySumAvgDaily",
          "gas",
        );
        setGazeZilnicChart(dailyConsumptionChart);

        const costChart = reportsHelper.createGraphOptions(
          data.gas,
          "monthlySumAvgDailyCost",
          "gas",
        );
        setGazeCostChart(costChart);
      } else {
        setGazeZilnicChart(emptyChartOptions);
        setGazeCostChart(emptyChartOptions);
      }
    } catch (error) {
      console.error("Error processing chart data:", error);
      setPrezentareChart(emptyChartOptions);
      setEnergieZilnicChart(emptyChartOptions);
      setEnergieCostChart(emptyChartOptions);
      setGazeZilnicChart(emptyChartOptions);
      setGazeCostChart(emptyChartOptions);
    }
  }, [data]); // Add data as dependency to update charts when data changes

  if (!data) {
    return <div>Rapoartele sunt indisponibile</div>;
  }

  return (
    <>
      <div className="rapoarte-grafic-detaliat-mare">
        <div className="rapoarte-grafic-detaliat-mare-header">
          <h2>Prezentare</h2>
          <p>Sume facturate lunar 2025 comparat cu 2024</p>
        </div>
        <ReactECharts
          option={prezentareChart}
          className="rapoarte-grafic-detaliat-prezentare"
          notMerge={true}
          lazyUpdate={true}
        />
      </div>

      <div className="rapoarte-grafic-detaliat-dublu">
        <h2>Energie electrica</h2>
        <div className="rapoarte-grafic-detaliat-dublu-row">
          <div className="rapoarte-grafic-detaliat-solo">
            <h2>Consum mediu zilnic pe luna aferent 2025 comparat cu 2024</h2>
            <ReactECharts
              option={energieZilnicChart}
              className="rapoarte-grafic-detaliat-solo-chart"
              notMerge={true}
              lazyUpdate={true}
            />
          </div>
          <div className="rapoarte-grafic-detaliat-solo">
            <h2>Cost total lunar 2025 comparat cu 2024</h2>
            <ReactECharts
              option={energieCostChart}
              className="rapoarte-grafic-detaliat-solo-chart"
              notMerge={true}
              lazyUpdate={true}
            />
          </div>
        </div>
      </div>

      <div className="rapoarte-grafic-detaliat-dublu">
        <h2>Gaze naturale</h2>
        <div className="rapoarte-grafic-detaliat-dublu-row">
          <div className="rapoarte-grafic-detaliat-solo">
            <h2>Consum mediu zilnic pe luna aferent 2025 comparat cu 2024</h2>
            <ReactECharts
              option={gazeZilnicChart}
              className="rapoarte-grafic-detaliat-solo-chart"
              notMerge={true}
              lazyUpdate={true}
            />
          </div>
          <div className="rapoarte-grafic-detaliat-solo">
            <h2>Cost total lunar 2025 comparat cu 2024</h2>
            <ReactECharts
              option={gazeCostChart}
              className="rapoarte-grafic-detaliat-solo-chart"
              notMerge={true}
              lazyUpdate={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}
