import { isValid, parse, getYear, getMonth } from "date-fns";

// Function to extract the year from a date string
export const extractYear = (dateValue) => {
  try {
    const parsedDate =
      typeof dateValue === "number"
        ? new Date(dateValue)
        : parse(dateValue, "yyyy-MM-dd", new Date());
    if (isValid(parsedDate)) {
      return getYear(parsedDate);
    }
    return parseInt(dateValue, 10); // Fallback for cases where date parsing fails
  } catch (e) {
    return parseInt(dateValue, 10); // Fallback for invalid date strings
  }
};

// Function to extract the month (1-12) from a date string
export const extractMonth = (dateValue) => {
  try {
    const parsedDate =
      typeof dateValue === "number"
        ? new Date(dateValue)
        : parse(dateValue, "yyyy-MM-dd", new Date());
    if (isValid(parsedDate)) {
      return getMonth(parsedDate) + 1; // date-fns returns month index (0-11)
    }
    return 1; // Default to January if parsing fails
  } catch (e) {
    return 1; // Default to January if an error occurs
  }
};

export const invoicesByYear = {
  2024: {
    yearlyAvgMWH: 0,
    yearlyAvgDailyQty: 0,
    yearlyAvgDailyCost: 0,
    1: {
      invoices: [],
      monthlySumMWH: 0,
      monthlySumAvgDaily: 0,
      monthlySumAvgDailyCost: 0,
    },
    2: {
      invoices: [],
      monthlySumMWH: 0,
      monthlySumAvgDaily: 0,
      monthlySumAvgDailyCost: 0,
    },
    3: {
      invoices: [],
      monthlySumMWH: 0,
      monthlySumAvgDaily: 0,
      monthlySumAvgDailyCost: 0,
    },
    4: {
      invoices: [],
      monthlySumMWH: 0,
      monthlySumAvgDaily: 0,
      monthlySumAvgDailyCost: 0,
    },
    5: {
      invoices: [],
      monthlySumMWH: 0,
      monthlySumAvgDaily: 0,
      monthlySumAvgDailyCost: 0,
    },
    6: {
      invoices: [],
      monthlySumMWH: 0,
      monthlySumAvgDaily: 0,
      monthlySumAvgDailyCost: 0,
    },
    7: {
      invoices: [],
      monthlySumMWH: 0,
      monthlySumAvgDaily: 0,
      monthlySumAvgDailyCost: 0,
    },
    8: {
      invoices: [],
      monthlySumMWH: 0,
      monthlySumAvgDaily: 0,
      monthlySumAvgDailyCost: 0,
    },
    9: {
      invoices: [],
      monthlySumMWH: 0,
      monthlySumAvgDaily: 0,
      monthlySumAvgDailyCost: 0,
    },
    10: {
      invoices: [],
      monthlySumMWH: 0,
      monthlySumAvgDaily: 0,
      monthlySumAvgDailyCost: 0,
    },
    11: {
      invoices: [],
      monthlySumMWH: 0,
      monthlySumAvgDaily: 0,
      monthlySumAvgDailyCost: 0,
    },
    12: {
      invoices: [],
      monthlySumMWH: 0,
      monthlySumAvgDaily: 0,
      monthlySumAvgDailyCost: 0,
    },
  },
  2025: {
    yearlyAvgMWH: 0,
    yearlyAvgDailyQty: 0,
    yearlyAvgDailyCost: 0,
    1: {
      invoices: [],
      monthlySumMWH: 0,
      monthlySumAvgDaily: 0,
      monthlySumAvgDailyCost: 0,
    },
    2: {
      invoices: [],
      monthlySumMWH: 0,
      monthlySumAvgDaily: 0,
      monthlySumAvgDailyCost: 0,
    },
    3: {
      invoices: [],
      monthlySumMWH: 0,
      monthlySumAvgDaily: 0,
      monthlySumAvgDailyCost: 0,
    },
    4: {
      invoices: [],
      monthlySumMWH: 0,
      monthlySumAvgDaily: 0,
      monthlySumAvgDailyCost: 0,
    },
    5: {
      invoices: [],
      monthlySumMWH: 0,
      monthlySumAvgDaily: 0,
      monthlySumAvgDailyCost: 0,
    },
    6: {
      invoices: [],
      monthlySumMWH: 0,
      monthlySumAvgDaily: 0,
      monthlySumAvgDailyCost: 0,
    },
    7: {
      invoices: [],
      monthlySumMWH: 0,
      monthlySumAvgDaily: 0,
      monthlySumAvgDailyCost: 0,
    },
    8: {
      invoices: [],
      monthlySumMWH: 0,
      monthlySumAvgDaily: 0,
      monthlySumAvgDailyCost: 0,
    },
    9: {
      invoices: [],
      monthlySumMWH: 0,
      monthlySumAvgDaily: 0,
      monthlySumAvgDailyCost: 0,
    },
    10: {
      invoices: [],
      monthlySumMWH: 0,
      monthlySumAvgDaily: 0,
      monthlySumAvgDailyCost: 0,
    },
    11: {
      invoices: [],
      monthlySumMWH: 0,
      monthlySumAvgDaily: 0,
      monthlySumAvgDailyCost: 0,
    },
    12: {
      invoices: [],
      monthlySumMWH: 0,
      monthlySumAvgDaily: 0,
      monthlySumAvgDailyCost: 0,
    },
  },
};
