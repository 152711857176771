import React from "react";
import ReactECharts from "echarts-for-react";
import ComponentLoading from "components/Loaders/ComponentLoading";
import * as echarts from "echarts";

export default function DashboardGrafic({
  isDataFetching = false,
  facturileMeleTableData = [],
}) {
  function formatDateForComparison(date) {
    const year = date.getFullYear();
    const monthNames = [
      "Ian",
      "Feb",
      "Mar",
      "Apr",
      "Mai",
      "Iun",
      "Iul",
      "Aug",
      "Sep",
      "Oct",
      "Noi",
      "Dec",
    ];
    const month = date.getMonth();
    return `${monthNames[month]} ${year}`;
  }

  function extractUniqueYearsAndMonths(array) {
    const uniqueYearMonths = new Set();
    array.forEach((obj) => {
      const date = obj.invoice_due_date ? new Date(obj.invoice_due_date) : null;
      if (date && !isNaN(date.getTime())) {
        const formattedDate = formatDateForComparison(date);
        uniqueYearMonths.add(formattedDate);
      }
    });

    return Array.from(uniqueYearMonths).sort((a, b) => {
      const dateA = new Date(
        a.split(" ")[1],
        monthNameToNumber(a.split(" ")[0]),
      );
      const dateB = new Date(
        b.split(" ")[1],
        monthNameToNumber(b.split(" ")[0]),
      );
      return dateA - dateB;
    });
  }

  function monthNameToNumber(monthName) {
    const months = {
      Ian: 0,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      Mai: 4,
      Iun: 5,
      Iul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Noi: 10,
      Dec: 11,
    };
    return months[monthName];
  }

  const getChartValues = (invoices) => {
    let result = {};
    if (invoices.length <= 0) {
      return result;
    }

    function getAmountsByMonth(objectsList) {
      const monthlyAmounts = {};

      objectsList.forEach((obj) => {
        if (!obj.invoice_due_date || !obj.amount) return;

        const date = new Date(obj.invoice_due_date);
        if (isNaN(date.getTime())) return;

        const formattedDate = formatDateForComparison(date);
        const amount = parseFloat(obj.amount) || 0;

        if (!monthlyAmounts[formattedDate]) {
          monthlyAmounts[formattedDate] = 0;
        }
        monthlyAmounts[formattedDate] += amount;
      });

      const months = extractUniqueYearsAndMonths(invoices);
      const result = months.map((month) => {
        return monthlyAmounts[month]
          ? Number(monthlyAmounts[month].toFixed(2))
          : 0;
      });

      return result;
    }

    const months = extractUniqueYearsAndMonths(invoices);

    const electricityInvoices = invoices.filter(
      (item) => item.utility_type !== "gas",
    );

    const gasInvoices = invoices.filter((item) => item.utility_type === "gas");

    const chartValuesForElectricity = getAmountsByMonth(electricityInvoices);

    const chartValuesForGas = getAmountsByMonth(gasInvoices);

    const options = {
      color: ["#52C2B5"],
      grid: { left: 50, right: 35 },
      legend: {
        orient: "horizontal",
        top: "0%",
        left: "5%",
        icon: "circle",
      },
      xAxis: {
        type: "category",
        data: months,
        boundaryGap: false, // This helps in aligning the first and last labels closer to the edges
      },
      yAxis: {
        type: "value",
        boundaryGap: ["0%", "0%"],
      },
      series: [
        {
          data: chartValuesForElectricity,
          type: "line",
          smooth: true,
          showSymbol: false,
          color: "#515FAB", // Green color for Series 2

          name: "Electricitate",
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: "#515FAB" },
              { offset: 1, color: "rgba(82, 194, 181, 0)" },
            ]),
          },
        },
        {
          color: "#eaa641", // Red color for Series 1
          data: chartValuesForGas,
          type: "line",
          smooth: true,
          name: "Gaz",
          showSymbol: false,
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: "#eaa641" },
              { offset: 1, color: "rgba(20, 40, 100, 0)" },
            ]),
          },
        },
      ],
      tooltip: {
        trigger: "axis",
      },
    };

    result = options;
    return result;
  };

  const chartValues = getChartValues(facturileMeleTableData);
  return (
    <div className="grafic-facturi">
      <div className="grafic-facturi-content">
        <h2>Grafic facturi</h2>
        <div className="grafic-facturi-selectors"></div>
      </div>
      {isDataFetching && (
        <ComponentLoading parrentClassName={"my-account-loading-div"} />
      )}
      {facturileMeleTableData && facturileMeleTableData.length === 0 && (
        <p className="my-account-no-invoices">Nu sunt facturi disponibile.</p>
      )}
      {facturileMeleTableData && facturileMeleTableData.length > 0 && (
        <ReactECharts option={chartValues} />
      )}
    </div>
  );
}
