import React, { useEffect, useState } from "react";
import Layout from "pages/Layout";
import SelectInput from "components/SelectInput";
import TabRaportSimplificat from "components/Rapoarte/TabRaportSimplificat";
import TabRaportDetaliat from "components/Rapoarte/TabRaportDetaliat";
import { apiLocuriDeConsum } from "api/locuriDeConsum.service";
import GAZ from "assets/images/common/gaz.png";
import CURENT from "assets/images/common/curent.png";
import { apiRapoarte } from "api/rapoarte.service";
import ComponentLoading from "components/Loaders/ComponentLoading";
import reportsHelper from "helpers/reportsHelper";
// import mockData from "data/mockData";
export default function Rapoarte() {
  const [selectedOption, setSelectedOption] = useState("RAPORT_SIMPLIFICAT");
  const [locuriConsum, setLocuriConsum] = useState([]);
  const [reportsRawData, setReportsRawData] = useState([]);
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [selectedLocDeConsum, setSelectedLocDeConsum] = useState("TOATE");
  const [dataProcessed, setDataProcessed] = useState([]);

  const getLocuriDeConsum = async () => {
    setIsDataFetching(true);
    const response = await apiLocuriDeConsum.getLocuriDeConsum();
    console.log("🚀 ~ getLocuriDeConsum ~ response:", response);
    if (response.isSuccess) {
      setLocuriConsum(response.response.data);
    }
    setIsDataFetching(false);
  };

  const filterDataBasedOnSelectedLocDeConsum = (data, selectedLocDeConsum) => {
    if (!data || selectedLocDeConsum === "TOATE") {
      return data;
    }

    return data.filter(
      (item) => item.Metering_Point_Number === selectedLocDeConsum,
    );
  };

  const getRapoarte = async () => {
    setIsDataFetching(true);
    const response = await apiRapoarte.getRapoarte();
    setIsDataFetching(false);
    if (response.isSuccess) {
      return response.response.data;
    }
    return [];
  };

  useEffect(() => {
    const init = async () => {
      await getLocuriDeConsum();
      const rapoarteData = await getRapoarte();

      if (rapoarteData && rapoarteData.length > 0) {
        setReportsRawData(rapoarteData);
        const dataFiltered = filterDataBasedOnSelectedLocDeConsum(
          rapoarteData,
          selectedLocDeConsum,
        );
        let data = reportsHelper.prepareReportsData(dataFiltered);
        setDataProcessed(data);
      }
    };

    init();
  }, []);

  const getCorrectTabRapoarte = (selectedOption, data) => {
    const tabComponents = {
      RAPORT_SIMPLIFICAT: <TabRaportSimplificat data={data} />,
      RAPORT_DETALIAT: <TabRaportDetaliat data={data} />,
      CONSUM_ORAR: <div>consum orar</div>,
    };

    return tabComponents[selectedOption] || tabComponents["RAPORT_SIMPLIFICAT"];
  };

  const changeLocDeConsum = (value) => {
    console.log("🚀 ~ changeLocDeConsum ~ value:", value);
    setSelectedLocDeConsum(value.value);
    const dataFiltered = filterDataBasedOnSelectedLocDeConsum(
      reportsRawData,
      selectedLocDeConsum,
    );
    let data = reportsHelper.prepareReportsData(dataFiltered, value.value);
    setDataProcessed(data);
  };

  if (isDataFetching) {
    return <ComponentLoading />;
  }
  return (
    <Layout>
      <SelectInput
        reactSelectClassName="bg-alb-green rapoarte_header-locDeConsum"
        isLabel={false}
        value={selectedLocDeConsum}
        onChange={changeLocDeConsum}
        placeholder="Loc de consum"
        options={[
          { value: "TOATE", label: "Toate locurile de consum" },
          ...locuriConsum.map((item) => ({
            value: item.metering_point_number,
            label: (
              <div>
                <img
                  alt="utility_icon"
                  src={item.utility_type === "Gaze" ? GAZ : CURENT}
                  height={"30px"}
                />
                {" " + item.address_summary}
              </div>
            ),
          })),
        ]}
      />
      <div className="rapoarte_header_row">
        <div className="rapoarte_header_selectors">
          <div
            onClick={() => {
              setSelectedOption("RAPORT_SIMPLIFICAT");
            }}
            className={`rapoarte_header_option ${
              selectedOption === "RAPORT_SIMPLIFICAT" &&
              "rapoarte_header_option-selected"
            }`}
          >
            <p>Raport simplificat</p>
          </div>
          <div
            onClick={() => {
              setSelectedOption("RAPORT_DETALIAT");
            }}
            className={`rapoarte_header_option ${
              selectedOption === "RAPORT_DETALIAT" &&
              "rapoarte_header_option-selected"
            }`}
          >
            <p>Raport detaliat</p>
          </div>
        </div>
        <div className="autocitire-table-line" />
      </div>
      {getCorrectTabRapoarte(selectedOption, dataProcessed)}
    </Layout>
  );
}
