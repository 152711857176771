import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ComponentLoading from "components/Loaders/ComponentLoading";
import { apiLocuriDeConsum } from "api/locuriDeConsum.service";
import { tableSuportHelper } from "helpers/tableHelpers/tableSuportHelperNew";
import { apiSuport } from "api/suport.service";

export default function DataTableSuport({ dashboard = false }) {
  const [rowsTableData, setRowsTableData] = useState([]);
  const [filteredRowsTableData, setFilteredRowsTableData] = useState([]);
  const [locuriConsum, setLocuriConsum] = useState([]);
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [selectedTipFurnizare, setSelectedTipFurnizare] = useState("TOATE");
  const [selectedLocDeConsum, setSelectedLocDeConsum] = useState("TOATE");
  const [selectedStatus, setSelectedStatus] = useState("TOATE");
  const [selectedTipTichet, setSelectedTipTichet] = useState("TOATE");

  const dt = useRef(null);

  const getDataCallback = async () => {
    setIsDataFetching(true);

    const response = await apiSuport.getSuportTableData();

    if (response.isSuccess) {
      setRowsTableData(response.response.data);
      setFilteredRowsTableData(response.response.data);
      setIsDataFetching(false);
    }
  };

  const getLocuriConsumCallback = async () => {
    const response = await apiLocuriDeConsum.getLocuriDeConsum();

    if (response.isSuccess) {
      setLocuriConsum(response.response.data);
    }
  };

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const handleChangeFilter = (
    value,
    filterType,
    rowsTableData,
    setFilteredRowsTableData,
  ) => {
    let filteredData;

    if (filterType === "status") {
      setSelectedStatus(value);
      setSelectedTipFurnizare("TOATE");
      setSelectedLocDeConsum("TOATE"); // Resetează starea pentru status
      setSelectedTipTichet("TOATE"); // Resetează starea pentru status
    } else if (filterType === "utility_type") {
      setSelectedTipFurnizare(value);
      setSelectedLocDeConsum("TOATE"); // Resetează starea pentru status
      setSelectedStatus("TOATE"); // Resetează starea pentru status
      setSelectedTipTichet("TOATE");
    } else if (filterType === "incident_type") {
      setSelectedTipTichet(value);
      setSelectedLocDeConsum("TOATE"); // Resetează starea pentru status
      setSelectedStatus("TOATE"); // Resetează starea pentru status
      setSelectedTipFurnizare("TOATE");
    }

    if (value !== "TOATE") {
      filteredData = rowsTableData.filter((item) => item[filterType] === value);
    } else {
      filteredData = [...rowsTableData];
    }
    setFilteredRowsTableData(filteredData);
  };

  const handleChangeLocDeConsum = (
    value,
    rowsTableData,
    setFilteredRowsTableData,
  ) => {
    setSelectedLocDeConsum(value); // Actualizează starea cu noua selecție
    setSelectedStatus("TOATE"); // Resetează starea pentru status
    setSelectedTipFurnizare("TOATE"); // Resetează starea pentru tipul de furnizare
    setSelectedTipTichet("TOATE");

    if (value === "TOATE") {
      setFilteredRowsTableData(rowsTableData);
    } else {
      const filteredData = rowsTableData.filter((item) => {
        return item.metering_point_id === value;
      });
      setFilteredRowsTableData(filteredData);
    }
  };

  useEffect(() => {
    getDataCallback();
    getLocuriConsumCallback();
  }, []);

  return (
    <>
      {isDataFetching && dashboard === false ? (
        <ComponentLoading parrentClassName={"my-account-loading-div"} />
      ) : (
        <DataTable
          loading={isDataFetching}
          ref={dt}
          exportFilename="Solicitari"
          className="suport-table"
          value={filteredRowsTableData}
          header={tableSuportHelper.header(
            locuriConsum,
            setFilteredRowsTableData,
            rowsTableData,
            handleChangeFilter,
            handleChangeLocDeConsum,
            selectedTipFurnizare,
            selectedLocDeConsum,
            selectedStatus,
            selectedTipTichet,
          )}
          paginator
          rows={10}
          removableSort
          sortField="id"
          sortOrder={-1} // 1 asc, -1 desc
        >
          <Column
            sortable
            field="incident_type"
            header="Tip tichet"
            body={tableSuportHelper.tableTipTichet}
          />
          <Column
            sortable
            field="id"
            header="Id"
            body={tableSuportHelper.tableId}
          />
          <Column
            sortable
            field="utility_type"
            header="Tip furnizare"
            body={tableSuportHelper.tableTipFurnizare}
          />
          <Column
            sortable
            field="subject"
            header="Subiect"
            body={tableSuportHelper.tableSubiect}
          />
          <Column
            sortable
            field="metering_point_address"
            header="Loc de consum"
            body={tableSuportHelper.tableLocDeConsum}
          />
          <Column
            sortable
            field="data"
            header="Data problemei"
            body={tableSuportHelper.tableDataProblemei}
          />
          <Column
            sortable
            field="status"
            header="Status"
            body={tableSuportHelper.tableStatus}
          />
          <Column
            sortable={false}
            field="resolution"
            header="Rezoluție"
            body={tableSuportHelper.tableResolution}
          />
        </DataTable>
      )}
    </>
  );
}
