import * as echarts from "echarts";
import { cloneDeep, isArray, orderBy } from "lodash";
import { extractYear, extractMonth, invoicesByYear } from "utils/dateUtils";
const formatter = (value) => {
  if (value >= 1000000000) {
    // For values >= 1 billion
    return (value / 1000000000).toFixed(2) + " bil";
  }
  if (value >= 1000000) {
    // For values >= 1 million
    return (value / 1000000).toFixed(2) + " mil";
  }
  if (value >= 100000) {
    // For values >= 100,000
    return (value / 1000).toFixed(2) + " mii";
  }

  return value.toFixed(0);
};
const defaultOptionsChart = {
  color: ["#52C2B5", "#4669E7"],
  grid: { left: 80, right: 35, bottom: 40 }, // Increased left margin for yAxis labels
  legend: {
    orient: "horizontal",
    top: "5%",
    left: "6%",
    icon: "circle",
  },
  xAxis: [
    {
      type: "category",
      boundaryGap: false,
      data: [
        "Ian",
        "Feb",
        "Mar",
        "Apr",
        "Mai",
        "Iun",
        "Iul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      axisTick: { show: false },
      axisLine: { show: false },
    },
  ],
  yAxis: {
    type: "value",
    boundaryGap: ["0%", "0%"],
  },
  series: [
    {
      name: "2024",
      type: "line",
      smooth: false,
      showSymbol: false,
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#52C2B5" },
          { offset: 1, color: "rgba(82, 194, 181, 0)" },
        ]),
      },
    },
    {
      name: "2025",
      type: "line",
      smooth: false,
      showSymbol: false,
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#4669E7" },
          { offset: 1, color: "rgba(164, 91, 157, 0)" },
        ]),
      },
    },
  ],
};

const createGraphOptions = (data, dataProperty, utility) => {
  if (data) {
    const processedData = {
      2024: [],
      2025: [],
    };

    // Iterate through the years and months to extract monthlySumMWH
    Object.keys(data).forEach((year) => {
      if (processedData[year]) {
        for (let month = 1; month <= 12; month++) {
          if (data[year][month]?.[dataProperty] !== undefined) {
            processedData[year].push(data[year][month][dataProperty]);
          }
        }
      }
    });

    console.log("🚀 ~ TabRaportSimplificat ~ processedData:", processedData);
    const newGraphData = cloneDeep(defaultOptionsChart); // Set colors based on utility type

    newGraphData.yAxis.axisLabel = {
      formatter: formatter,
      show: true,
    };
    if (utility === "energy") {
      newGraphData.color = ["#52C2B5", "#4669E7"];

      // Set series colors
      if (newGraphData.series && newGraphData.series.length >= 2) {
        newGraphData.series[0].areaStyle = {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#52C2B5" },
            { offset: 1, color: "rgba(82, 194, 181, 0)" },
          ]),
        };

        newGraphData.series[1].areaStyle = {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#4669E7" },
            { offset: 1, color: "rgba(164, 91, 157, 0)" },
          ]),
        };
      }
    } else if (utility === "gas") {
      newGraphData.color = ["#F29857", "#8E41A9"];

      // Set series colors
      if (newGraphData.series && newGraphData.series.length >= 2) {
        newGraphData.series[0].areaStyle = {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#F29857" },
            { offset: 1, color: "rgba(242, 152, 87, 0)" },
          ]),
        };

        newGraphData.series[1].areaStyle = {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#8E41A9" },
            { offset: 1, color: "rgba(142, 65, 169, 0)" },
          ]),
        };
      }
    }

    // Calculate min and max values from the data
    let minValue = Infinity;
    let maxValue = -Infinity;

    if (processedData["2024"] && processedData["2024"].length > 0) {
      processedData["2024"].forEach((val) => {
        if (!isNaN(val) && val !== undefined) {
          minValue = Math.min(minValue, val);
          maxValue = Math.max(maxValue, val);
        }
      });
    }

    if (processedData["2025"] && processedData["2025"].length > 0) {
      processedData["2025"].forEach((val) => {
        if (!isNaN(val) && val !== undefined) {
          minValue = Math.min(minValue, val);
          maxValue = Math.max(maxValue, val);
        }
      });
    }

    // Set yAxis min and max values
    newGraphData.yAxis.min = minValue !== Infinity ? minValue : undefined;
    console.log("🚀 ~ useEffect ~ minValue:", minValue);
    newGraphData.yAxis.max = maxValue !== -Infinity ? maxValue : undefined;
    console.log("🚀 ~ useEffect ~ maxValue:", maxValue);

    // Normalize data for 2024 if it exists
    if (processedData["2024"] && processedData["2024"].length > 0) {
      // Use original data for the chart display
      newGraphData.series[0].data = processedData["2024"];

      // No need to reset area styles here as we've already set them above
    }

    // Do the same for 2025 data
    if (processedData["2025"] && processedData["2025"].length > 0) {
      newGraphData.series[1].data = processedData["2025"];

      // No need to reset area styles here as we've already set them above
    }
    console.log("🚀 ~ useEffect ~ newGraphData:", newGraphData);
    // Set the state with the processed data
    return newGraphData;
  }
};

const createGraphOptionsForInvoiceAmounts = (data) => {
  console.log("🚀 ~ createGraphOptionsForInvoiceAmounts ~ data:", data);
  if (data) {
    const processedData = {
      2024: Array(12).fill(0),
      2025: Array(12).fill(0),
    };

    // Process all invoice data regardless of utility type
    // Assuming data is structured as { gas: {...}, energy: {...} }
    Object.keys(data).forEach((year) => {
      if (processedData[year]) {
        for (let month = 1; month <= 12; month++) {
          if (data[year][month]?.invoices) {
            // Sum up invoice amounts for the month
            const totalAmount = data[year][month].invoices.reduce(
              (sum, invoice) => {
                // Try multiple possible invoice amount properties
                const amount = parseFloat(invoice?.amount || 0);
                return sum + amount;
              },
              0,
            );

            // Add to existing total (to combine gas and electricity)
            processedData[year][month - 1] += totalAmount;
          }
        }
      }
    });

    console.log("📊 Combined Invoice Amounts Processed Data:", processedData);
    const newGraphData = cloneDeep(defaultOptionsChart); // Create a deep copy
    console.log(
      "🚀 ~ createGraphOptionsForInvoiceAmounts ~ newGraphData:",
      newGraphData,
    );

    newGraphData.yAxis.axisLabel = {
      formatter: formatter,
      show: true,
    };

    // Set a consistent color scheme for all invoice data
    newGraphData.color = ["#4669E7", "#F29857"];

    // Set consistent series colors
    if (newGraphData.series && newGraphData.series.length >= 2) {
      newGraphData.series[0].areaStyle = {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#4669E7" },
          { offset: 1, color: "rgba(70, 105, 231, 0)" },
        ]),
      };

      newGraphData.series[1].areaStyle = {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#F29857" },
          { offset: 1, color: "rgba(242, 152, 87, 0)" },
        ]),
      };
    }

    // Calculate min and max values from the data
    let minValue = Infinity;
    let maxValue = -Infinity;

    if (processedData["2024"] && processedData["2024"].length > 0) {
      processedData["2024"].forEach((val) => {
        if (!isNaN(val) && val !== undefined) {
          minValue = Math.min(minValue, val);
          maxValue = Math.max(maxValue, val);
        }
      });
    }

    if (processedData["2025"] && processedData["2025"].length > 0) {
      processedData["2025"].forEach((val) => {
        if (!isNaN(val) && val !== undefined) {
          minValue = Math.min(minValue, val);
          maxValue = Math.max(maxValue, val);
        }
      });
    }

    // Set yAxis min and max values with some padding
    newGraphData.yAxis.min = minValue > 0 ? 0 : minValue * 0.9;
    newGraphData.yAxis.max = maxValue * 1.1;

    // Update series data
    newGraphData.series[0].data = processedData["2024"];
    newGraphData.series[1].data = processedData["2025"];

    console.log("📊 Combined Invoice Amounts Chart Data:", newGraphData);
    return newGraphData;
  }
};

const prepareReportsData = (data) => {
  if (!isArray(data) || data.length === 0) {
    return [];
  }

  // Sort data by invoice date
  const dataSortedByYear = orderBy(data, ["invoice_date"], ["asc"]);

  // Create separate structures for gas and electricity
  const gasInvoicesByYear = {
    2024: initializeYearStructure(),
    2025: initializeYearStructure(),
  };

  const energyInvoicesByYear = {
    2024: initializeYearStructure(),
    2025: initializeYearStructure(),
  };

  // Group invoices by utility type, year and month
  dataSortedByYear.forEach((item) => {
    const year = extractYear(item.invoice_date);
    const month = extractMonth(item.invoice_date);
    const utilityType = item.Utility_Type;

    // Only process years 2024 and 2025
    if (year !== 2024 && year !== 2025) return;

    // Route invoice to correct utility type structure
    if (
      utilityType === "Gaz" &&
      gasInvoicesByYear[year] &&
      gasInvoicesByYear[year][month]
    ) {
      gasInvoicesByYear[year][month].invoices.push(item);
    } else if (
      utilityType === "Electricitate" &&
      energyInvoicesByYear[year] &&
      energyInvoicesByYear[year][month]
    ) {
      energyInvoicesByYear[year][month].invoices.push(item);
    }
  });

  // Process gas data
  processYearlyData(gasInvoicesByYear);

  // Process electricity data
  processYearlyData(energyInvoicesByYear);

  return {
    gas: gasInvoicesByYear,
    energy: energyInvoicesByYear,
  };
};

// Helper function to initialize the year structure
const initializeYearStructure = () => {
  const yearStructure = {
    yearlyAvgMWH: 0,
    yearlyAvgDailyQty: 0,
    yearlyAvgDailyCost: 0,
  };

  // Initialize each month
  for (let month = 1; month <= 12; month++) {
    yearStructure[month] = {
      invoices: [],
      monthlySumMWH: 0,
      monthlySumAvgDaily: 0,
      monthlySumAvgDailyCost: 0,
    };
  }

  return yearStructure;
};

// Helper function to process monthly and yearly calculations
const processYearlyData = (invoicesByYear) => {
  // Calculate monthly sums for each metric
  Object.keys(invoicesByYear).forEach((year) => {
    Object.keys(invoicesByYear[year]).forEach((month) => {
      // Skip the yearly total properties
      if (
        month === "yearlyAvgMWH" ||
        month === "yearlyAvgDailyQty" ||
        month === "yearlyAvgDailyCost"
      )
        return;

      const monthData = invoicesByYear[year][month];

      // Sum up the Invoiced_Monthly_Qty_MWH values
      monthData.monthlySumMWH = monthData.invoices.reduce((sum, invoice) => {
        const qty = parseFloat(invoice.Invoiced_Monthly_Qty_MWH) || 0;
        return sum + qty;
      }, 0);

      // Sum up the Avg_Daily_Invoiced_Qty_MWH values
      monthData.monthlySumAvgDaily = monthData.invoices.reduce(
        (sum, invoice) => {
          const qty = parseFloat(invoice.Avg_Daily_Invoiced_Qty_MWH) || 0;
          return sum + qty;
        },
        0,
      );

      // Sum up the Average_Daily_Cost values
      monthData.monthlySumAvgDailyCost = monthData.invoices.reduce(
        (sum, invoice) => {
          const cost = parseFloat(invoice.Average_Daily_Cost) || 0;
          return sum + cost;
        },
        0,
      );
    });
  });

  // Calculate yearly averages
  Object.keys(invoicesByYear).forEach((year) => {
    let yearlyTotalMWH = 0;
    let yearlyTotalAvgDaily = 0;
    let yearlyTotalAvgDailyCost = 0;
    let monthsWithInvoices = 0;

    // Sum up all months for this year
    for (let month = 1; month <= 12; month++) {
      const monthData = invoicesByYear[year][month];
      const invoiceCount = monthData.invoices.length;

      if (invoiceCount > 0) {
        // Calculate monthly average based on invoice count
        yearlyTotalMWH += monthData.monthlySumMWH / invoiceCount;
        yearlyTotalAvgDaily += monthData.monthlySumAvgDaily / invoiceCount;
        yearlyTotalAvgDailyCost +=
          monthData.monthlySumAvgDailyCost / invoiceCount;
        monthsWithInvoices++;
      }
    }

    // Store the yearly averages:
    // 1. First get the average per month with invoices
    // 2. Then divide by 12 to get the daily average over the year
    const divisor = monthsWithInvoices > 0 ? monthsWithInvoices : 1;
    invoicesByYear[year].yearlyAvgMWH = yearlyTotalMWH / divisor / 12;
    invoicesByYear[year].yearlyAvgDailyQty = yearlyTotalAvgDaily / divisor / 12;
    invoicesByYear[year].yearlyAvgDailyCost =
      yearlyTotalAvgDailyCost / divisor / 12;
  });
};

const reportsHelper = {
  createGraphOptions,
  createGraphOptionsForInvoiceAmounts,
  prepareReportsData,
};

export default reportsHelper;
